import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, jp, vi } from 'I18n';
import { getLocalStorage, STORAGE } from 'Utils/storage';

const resources = {
	vi,
	en,
	jp,
};

const INIT_LANGUAGE = getLocalStorage(STORAGE.LANGUAGE) || 'jp';

i18next.use(initReactI18next).init({
	resources,
	ns: ['common', 'course', 'menu', 'error_message', "student"],
	defaultNS: 'common',
	backend: {
		loadPath: './{{lng}}.json',
	},
	react: {
		useSuspense: true,
	},
	lng: INIT_LANGUAGE,
	fallbackLng: INIT_LANGUAGE,
	preload: [INIT_LANGUAGE],
	// keySeparator: false,
	interpolation: { escapeValue: false },
});

i18next.off('languageChanged');
i18next.on('languageChanged', (language) => {
	window.localStorage.setItem('LANGUAGE', language);
});

export default i18next;
